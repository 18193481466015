<template>
  <modal-wrapper :id="id" hide-footer size="md">
    <template #title>
      {{ sharing.modalTitle }}
    </template>
    <template #body>
      <share-network
        v-for="network in networks"
        :network="network.network"
        :key="network.network"
        :style="{backgroundColor: network.color}"
        :url="sharing.url"
        :title="sharing.title"
        :description="sharing.description"
        :quote="sharing.quote"
        :hashtags="sharing.hashtags"
        :twitterUser="sharing.twitterUser"
      >
        <i :class="network.icon"></i>
        <span>{{ network.name }}</span>
      </share-network>
    </template>
  </modal-wrapper>
</template>

<script>
import { isPresent } from '../../utils/validators';

const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');

export default {
  name: 'ModalBadgeShare',
  components: { ModalWrapper },
  props: {
    id: String,
    uri: String,
    title: String,
    description: String,
    quote: String,
    hashtags: String,
    modalTitle: String,
  },
  data() {
    return {
      sharing: {
        modalTitle: this.modalTitle || 'Condivisione Badge',
        url: isPresent(this.uri) ? this.uri : 'link non disponibile',
        title: this.title || 'Questo è il mio badge',
        description: this.description || 'Ecco l\'indirizzo per visualizzare il mio badge',
        quote: this.quote || 'CR Pagamenti è un network per aumentare la tua reputazione online',
        hashtags: this.hashtags || 'cr,crpagamenti,onlinereputation',
      },
      networks: [
        {
          network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366',
        },
        {
          network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc',
        },
        {
          network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333',
        },
        {
          network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2',
        },
        {
          network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5',
        },
        {
          network: 'skype', name: 'Skype', icon: 'fab fah fa-lg fa-skype', color: '#00aff0',
        },
        {
          network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333',
        },
        {
          network: 'line', name: 'Line', icon: 'fab fah fa-lg fa-line', color: '#00c300',
        },
        {
          network: 'viber', name: 'Viber', icon: 'fab fah fa-lg fa-viber', color: '#59267c',
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
